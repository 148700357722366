import { cookieName } from '../configs/cookies';
import { store } from '../store/store';
import Cookies from 'universal-cookie';

/**
 * AbstractAuthAPIs class
 */
export default abstract class AbstractAuthAPIs {
    /**
     * Retrieve apiKey from instance cookie
     */
    protected static getApiKey(): string|null {
        let apiKey = null;

        // Try to retrieve apiKey from redux state
        apiKey = store.getState().instance.apiKey;

        // Try to retrieve apiKey from instance cookie as fallback
        if (null === apiKey) {
            const instanceCookie = (new Cookies()).get(cookieName);

            apiKey = instanceCookie ? instanceCookie.apiKey : null;
        }

        return apiKey;
    }
};
