import React from 'react';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import { useCookies } from 'react-cookie';
import { cookieName } from '../configs/cookies';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchCountries } from '../store/slices/countriesSlice';
import { clearOrderStatusesState, fetchOrderStatuses } from '../store/slices/orderStatusesSlice';
import { clearScopesGroupsState, fetchScopesGroups } from '../store/slices/scopesGroupsSlice';
import { clearInstanceState, IInstanceState, setInstanceIsLogged, setInstanceState } from '../store/slices/instanceSlice';
import { clearInstanceWalletState } from '../store/slices/instanceWalletSlice';
import SessionAPIs from '../APIs/SessionAPIs';
import { UIDefaultTheme, UITypography, overrideComponentsUsingTheme } from '../configs/materialUI';
import { frFR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Theme, CssBaseline, responsiveFontSizes } from '@mui/material';
import Toast from './Toast/Toast';
import AppTree from './AppTree';
import Spinner from './Spinner/Spinner';
import ICountry from '../models/ICountry';
import ISession from '../models/ISession';
import '../styles/_reset.scss';

const App: React.FC = () => {
    // Use of redux
    const dispatch = useAppDispatch();
    const instanceState: IInstanceState = useAppSelector(state => state.instance);
    const countries: Array<ICountry>|null = useAppSelector(state => state.countries.data);

    // Use of react-cookie hooks
    const [cookie, /** setCookie */, removeCookie] = useCookies([cookieName]);

    // Create a mui theme from configuration
    // Responsive font sizes & fr locale
    const loadedTheme: Theme = React.useMemo(() =>
        overrideComponentsUsingTheme(
            responsiveFontSizes(
                createTheme({ ...UIDefaultTheme, typography: UITypography }, frFR)
        ))
    , []);

    // useEffect when App is mouting
    React.useEffect(() => {
        // If pathname is not '/'
        '/' !== window.location.pathname &&
            // Replace pathname with '/'
            window.history.replaceState({}, '', '/');
    }, []);

    // useEffect once when App is mounting
    React.useEffect(() => {
        // Set default moment timezone globally
        moment.tz.setDefault('Europe/Paris');
        // Set default moment locale
        moment.locale('fr');
    }, [])

    // Callback used to change the current user session activity
    const toggleSessionActivity = React.useCallback((isActive: boolean) => {
        null !== instanceState.sessionId &&
            // Call API to active or not session related to instance
            SessionAPIs.patchSession(instanceState.sessionId, { isActive: isActive });
    }, [instanceState.sessionId])

    // Callback used to validate instance cookie
    const validateInstanceCookie = React.useCallback((instanceCookie: any) => {
        // Retrieve Session resource using API
        SessionAPIs.getSession({ id: instanceCookie.sessionId })
            // On succesful call
            .then((data: Array<ISession>) =>
                (data && data[0]) ?
                    data[0].hasToSignIn ?
                        // Instance user has a valid cookie but has to sign in again
                        dispatch(setInstanceIsLogged(false))
                        // Paste instance cookie content into redux state & log instance
                        : dispatch(setInstanceState({ ...instanceCookie, isLogged: true }))
                // No session => unlog instance user
                : dispatch(setInstanceIsLogged(false))
            );
    }, [dispatch])

    // useEffect whenever instance cookie value changes
    React.useEffect(() => {
        cookie[cookieName] ?
            // Cookie exists & we try to validate it
            validateInstanceCookie(cookie[cookieName])
            // There is no cookie, so we are no longer logged
            : dispatch(setInstanceIsLogged(false));
    }, [cookie, validateInstanceCookie, dispatch])

    // useEffect when app is mounted & someone is logged or unlogged
    React.useEffect(() => {
        undefined !== instanceState.isLogged &&
            // Call API to change session activity, only if a sessionId is set in redux state
            toggleSessionActivity(instanceState.isLogged);
    }, [instanceState.isLogged, toggleSessionActivity])

    // useEffect once when app is mounted & nobody is logged
    React.useEffect(() => {
        if (false === instanceState.isLogged) {
            // Remove instance cookie
            removeCookie(cookieName, { path: '/' });
            // Clear all fetched scopes groups
            dispatch(clearScopesGroupsState());
            // Clear all fetched order status
            dispatch(clearOrderStatusesState());
            // Clear instance related redux states
            dispatch(clearInstanceState());
            dispatch(clearInstanceWalletState());
        } else if (true === instanceState.isLogged) {
            // Fetch all scopes groups from API to redux state
            dispatch(fetchScopesGroups());
            // Fetch all order status from API to redux state
            dispatch(fetchOrderStatuses());
        }
    }, [instanceState.isLogged, removeCookie, dispatch])

    // useEffect when App is mounted
    React.useEffect(() => {
        // Fetch all countries from API to redux state
        null === countries && dispatch(fetchCountries());
    }, [countries, dispatch]);

    return (
        <ThemeProvider theme={loadedTheme}>
            <CssBaseline />
            <Toast />
            {
                /**
                 * Once we know if either current user is logged or not
                 * We can render AppTree, before that display Spinner
                 */
                undefined === instanceState.isLogged ?
                    (<Spinner isFullscreen />)
                    : (<AppTree />)
            }
        </ThemeProvider>
    );
};

export default App;
