import React from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { Formik, FormikProps, Form } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IInstanceCompanyState, instanceIsScoped, selectInstanceAccountTypeName, setInstanceCompany } from '../../store/slices/instanceSlice';
import AccountAPIs from '../../APIs/AccountAPIs';
import OtherAPIs from '../../APIs/OtherAPIs';
import Tools from '../../helpers/Tools';
import { cookieOptions, cookieName } from '../../configs/cookies';
import {
    Divider,
    Button,
    Stack,
    Typography,
    Alert,
    FormHelperText,
    Paper,
} from '@mui/material';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import FormikInput from '../FormikComponent/FormikInput';
import FormikPhone from '../FormikComponent/FormikPhone';
import SelectCompanyType from '../SelectEntities/SelectCompanyType';
import SelectCountry from '../SelectEntities/SelectCountry';
import Spinner from '../Spinner/Spinner';
import EAccountTypes from '../../interfaces/EAccountTypes';
import IUploadFile from '../../models/IUploadFile';
import ICompany from '../../models/ICompany';
import ICountry from '../../models/ICountry';

const CompanyForm: React.FC = () => {
    // Use of hooks
    const [alert, setAlert] = React.useState<boolean>(false);
    const [company, setCompany] = React.useState<ICompany|null>(null);
    const [initialValues, setInitialValues] = React.useState<any>(null);

    // Use of redux
    const dispatch = useAppDispatch();
    const instanceAccountTypeSlug: string|null = useAppSelector(selectInstanceAccountTypeName(true));
    const instanceCompany: IInstanceCompanyState = useAppSelector(state => state.instance.account!.company);
    const instanceScoped: boolean = useAppSelector(instanceIsScoped(['account_owner', 'account_info_update']));
    const countries: Array<ICountry> = useAppSelector(state => state.countries.data!);

    // Use of react-cookie hooks
    const [cookie, setCookie] = useCookies([cookieName]);

    // Use react-router-dom hooks
    const location = useLocation();
    const highlightMissingFields: boolean|null = location.state as boolean|null;

    // Const which determines if instance user can edit informations
    const userCanEditInfo = instanceScoped || EAccountTypes.EDITOR === instanceAccountTypeSlug;

    const unawareInitialValues = React.useRef<any>({
        name: '',
        email: '',
        phonePrefix: countries[0].phonePrefix,
        phone: '',
        phonePrefixPerso: countries[0].phonePrefix,
        phonePerso: '',
        country: null,
        address: '',
        postalCode: '',
        city: '',
        website: '',
        siret: '',
        iban: '',
        bio: '',
        logo: null,
        companyType: null,
    });

    // Define yup validation schema for formik form
    const validationSchema = Yup.object({
        country: Yup
            .mixed()
            .required("Veuillez spécifier le pays de résidence"),
        name: Yup
            .string()
            .required("Veuillez spécifier le nom"),
        companyType: Yup
            .mixed()
            .required("Veuillez spécifier le type"),
        email: Yup
            .string()
            .email('Veuillez rentrer une adresse email valide'),
        siret: Yup
            .string()
            .length(14, 'Veuillez renseigner un numéro de SIRET valide'),
        iban: Yup
            .string()
            .min(14, 'Veuillez renseigner un IBAN valide')
            .max(34, 'Veuillez renseigner un IBAN valide'),
        phone: Yup
            .string()
            .length(9, 'Veuillez renseigner un numéro de téléphone valide')
            .nullable(),
        phonePerso: Yup
            .string()
            .length(9, 'Veuillez renseigner un numéro de téléphone valide')
            .nullable(),
        logo: Yup
            .mixed()
            .nullable(),
    });

    // useEffect when component is mounting
    React.useEffect(() => {
        null === company &&
            // Call API to get Company information
            AccountAPIs.getCompany({ id: instanceCompany.id })
                // On successful API call
                .then((data: Array<ICompany>) => null !== data && data[0] && setCompany(data[0]));
    }, [company, instanceCompany.id]);

    // useEffect triggered once company hook value is no longer undefined
    React.useEffect(() => {
        null !== company &&
            // Setup initial values of formik form
            setInitialValues({
                name: company.name ?? '',
                email: company.email ?? '',
                phonePrefix: company.phonePrefix ?? countries[0].phonePrefix,
                phone: company.phone ?? '',
                phonePrefixPerso: company.account.user.phonePrefix ?? countries[0].phonePrefix,
                phonePerso: company.account.user.phone ?? '',
                country: { label: company.country.name, value: String(company.country.id) },
                address: company.address ?? '',
                postalCode: company.postalCode ?? '',
                city: company.city ?? '',
                website: company.website ?? '',
                siret: company.siret ?? '',
                iban: company.iban ?? '',
                bio: company.bio ?? '',
                logo: company.logo ?? null,
                companyType: company?.companyType ? {
                    label: `${company.companyType.acronym} : ${company.companyType.name}`,
                    value: String(company.companyType.id),
                } : null,
            });
    }, [company, countries])

    // Callback used to safely upload logo on our API & update formik values
    const safelyUploadLogo = React.useCallback(async (values: any) => {
        // Retrieve logo from formik values
        const logo = values.logo;

        // If a logo was choosen
        if (
            null !== logo &&
            (logo instanceof File || logo instanceof Blob) &&
            logo.type.includes('image')
        ) {
            // Await API call to upload the logo
            await OtherAPIs.postUploadFile(logo, values.name)
                // On successful call
                .then((data: IUploadFile) => {
                    // Set logo value with path of the uploaded logo
                    values.logo = data.url;
                });
        }
    }, [])

    // Callback used to handle redux & cookie state update
    const handleReduxAndCookieUpdate = React.useCallback((values: any) => {
        // Define new redux state/cookie company content
        const newCompanyState = {
            name: values.name,
            logo: values.logo,
            iban: Boolean(values.iban),
            siret: Boolean(values.siret),
            country: Boolean(values.country),
            address: Boolean(values.address),
            city: Boolean(values.city),
            postalCode: Boolean(values.postalCode),
        };

        // Change company logo & iban presence in redux state
        dispatch(setInstanceCompany({ ...instanceCompany, ...newCompanyState }));

        // Change company logo & iban presence in cookie if it exists
        cookie[cookieName] &&
            setCookie(cookieName, {
                ...cookie[cookieName],
                account: {
                    ...cookie[cookieName].account,
                    company: {
                        ...cookie[cookieName].account.company,
                        ...newCompanyState,
                    },
                },
            }, cookieOptions);
    }, [instanceCompany, cookie, dispatch, setCookie])

    // Callback which define onSubmit of formik form
    const handleFormikSubmit = React.useCallback(async (values: any) => {
        if (null !== company) {
            // Await upload of logo
            await safelyUploadLogo(values);

            // Convert formik values to be sent as body request
            let companyBodyRequest = Tools.convertToBodyRequest(values);
            companyBodyRequest.companyType = companyBodyRequest.companyType.value;
            companyBodyRequest.country = companyBodyRequest.country.value;

            // Update existing Company information
            AccountAPIs.patchCompany(company.id, companyBodyRequest)
                .then((data: ICompany) => {
                    if (null !== data) {
                        // Update company hook value
                        setCompany(data);
                        // Show success alert
                        setAlert(true);
                        // Scroll to top
                        Tools.scrollInDashboard('top');
                        // Update redux & cookie state
                        handleReduxAndCookieUpdate(values);
                    }
                });
        }
    }, [company, safelyUploadLogo, handleReduxAndCookieUpdate]);

    // Callback use to reset formik fields to their initial value
    const resetFormikFields = React.useCallback((formikProps: FormikProps<any>, fields: Array<string>) => {
        // Use pre-build formik function
        fields.forEach((field) => formikProps.setFieldValue(field, unawareInitialValues.current[field], false));
    }, []);

    return (
        null !== company && null !== initialValues ? (
            <>
                {
                    alert && (
                        <Alert sx={{ marginBottom: 3 }} onClose={() => setAlert(false)}>
                            {`Vos informations ont été mises à jour avec succès.`}
                        </Alert>
                    )
                }
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleFormikSubmit}
                >
                    {(formikProps: FormikProps<any>) => (
                        <Form>
                            <Typography variant='h4' paddingBottom={3} color='primary.main'>
                                {`Mes informations de compte`}
                            </Typography>
                            <Stack direction={{ md: 'row', xs: 'column' }} rowGap={5} columnGap={5}>
                                <Stack rowGap={5} columnGap={5} width={{ md: 1/2 }}>
                                    <Paper sx={{ padding: 5 }}>
                                        <Typography variant='h5' paddingBottom={2}>
                                            {`Informations de votre société/entreprise`}
                                        </Typography>
                                        <ImageDropzone
                                            disabled={!userCanEditInfo}
                                            innerLabel='Glissez-déposez un logo ici, ou cliquez pour en sélectionner un depuis vos fichiers.'
                                            maxImageWeight={400}
                                            initialImage={formikProps.values.logo}
                                            error={Boolean(formikProps.errors.logo)}
                                            onDrop={(image) => formikProps.setFieldValue('logo', image)}
                                            onPreviewClose={() => formikProps.setFieldValue('logo', null)}
                                            sx={{ height: 150 }}
                                            previewSx={{ height: 150 }}
                                        />
                                        <FormikInput
                                            disabled
                                            upperLabel='Nom ou dénomination'
                                            name='name'
                                            sx={{ paddingY: 3 }}
                                        />
                                        <SelectCompanyType
                                            name='companyType'
                                            upperlabel='Forme juridique'
                                            sx={{ paddingBottom: 3 }}
                                        />
                                        <FormikInput
                                            disabled={!userCanEditInfo}
                                            upperLabel='Numéro SIRET'
                                            name='siret'
                                            warnings={[
                                                {
                                                    condition: true === highlightMissingFields && !formikProps.values.siret,
                                                    text: 'Le numéro SIRET doit être renseigné pour faire une demande de paiement',
                                                }
                                            ]}
                                        />
                                    </Paper>
                                    <Paper sx={{ padding: 5 }}>
                                        <Typography variant='h5' paddingBottom={2}>
                                            {`Données de paiement`}
                                        </Typography>
                                        <FormikInput
                                            disabled={!userCanEditInfo}
                                            upperLabel='Numéro IBAN'
                                            name='iban'
                                            warnings={[
                                                {
                                                    condition: true === highlightMissingFields && !formikProps.values.iban,
                                                    text: 'Le numéro IBAN doit être renseigné pour faire une demande de paiement',
                                                }
                                            ]}
                                            sx={{ paddingBottom: 3 }}
                                        />
                                    </Paper>
                                </Stack>
                                <Stack rowGap={5} columnGap={5} width={{ md: 1/2 }}>
                                    <Paper sx={{ padding: 5 }}>
                                        <Typography variant='h5' paddingBottom={2}>
                                            {`Localisation de votre société/entreprise`}
                                        </Typography>
                                        <SelectCountry
                                            name='country'
                                            upperlabel='Pays de résidence'
                                            sx={{ paddingBottom: 3 }}
                                            onChange={option => {
                                                if (option) {
                                                    // Change phonePrefix value to the selected country phone prefix and reset phone value
                                                    resetFormikFields(formikProps, ['phone']);
                                                    const country = countries.find(country => country.id === Number(option.value))!;
                                                    formikProps.setFieldValue('phonePrefix', country.phonePrefix, false);
                                                }
                                            }}
                                        />
                                        <FormikInput
                                            disabled={!userCanEditInfo}
                                            upperLabel='Adresse'
                                            name='address'
                                            warnings={[
                                                {
                                                    condition: true === highlightMissingFields && !formikProps.values.address,
                                                    text: "L'adresse doit être renseigné pour faire une demande de paiement",
                                                }
                                            ]}
                                            sx={{ paddingBottom: 3 }}
                                        />
                                        <FormikInput
                                            disabled={!userCanEditInfo}
                                            mask='_'
                                            format='#####'
                                            upperLabel='Code postal'
                                            name='postalCode'
                                            warnings={[
                                                {
                                                    condition: true === highlightMissingFields && !formikProps.values.postalCode,
                                                    text: 'Le code postal doit être renseigné pour faire une demande de paiement',
                                                }
                                            ]}
                                            sx={{ paddingBottom: 3 }}
                                        />
                                        <FormikInput
                                            disabled={!userCanEditInfo}
                                            upperLabel='Ville - Commune'
                                            name='city'
                                            warnings={[
                                                {
                                                    condition: true === highlightMissingFields && !formikProps.values.city,
                                                    text: 'La ville doit être renseigné pour faire une demande de paiement',
                                                }
                                            ]}
                                        />
                                    </Paper>
                                    <Paper sx={{ padding: 5 }}>
                                        <Typography variant='h5' paddingBottom={2}>
                                            {`Données de contact`}
                                        </Typography>
                                        <FormikInput
                                            disabled={!userCanEditInfo}
                                            upperLabel='Email'
                                            name='email'
                                            sx={{ paddingBottom: 3 }}
                                        />
                                        <FormikPhone
                                            formikProps={formikProps}
                                            prefixName='phonePrefix'
                                            phoneName='phone'
                                            upperLabel='Numéro de téléphone professionnel'
                                            disabled={!userCanEditInfo}
                                            sx={{ paddingBottom: 3 }}
                                        />
                                        <FormikPhone
                                            formikProps={formikProps}
                                            prefixName='phonePrefixPerso'
                                            phoneName='phonePerso'
                                            upperLabel='Numéro de téléphone personnel'
                                            disabled={!userCanEditInfo}
                                        />
                                    </Paper>
                                </Stack>
                            </Stack>
                            {
                                userCanEditInfo && (
                                    <>
                                        <Divider variant='middle' sx={{ marginY: 4 }} />
                                        {
                                            !formikProps.isValid && (
                                                <FormHelperText error>
                                                    {`Le formulaire contient une ou plusieurs erreur(s).`}
                                                </FormHelperText>
                                            )
                                        }
                                        <Button
                                            variant='contained'
                                            type='submit'
                                            disabled={!formikProps.isValid || false === formikProps.dirty}
                                        >
                                            {`Sauvegarder les changements`}
                                        </Button>
                                    </>
                                )
                            }
                        </Form>
                    )}
                </Formik>
            </>
        ) : (<Spinner />)
    );
};

export default CompanyForm;
