import React from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch } from '../../store/hooks';
import { setInstanceState } from '../../store/slices/instanceSlice';
import SecurityAPIs from '../../APIs/SecurityAPIs';
import { cookieOptions, cookieName } from '../../configs/cookies';
import {
    Stack,
    Box,
    Typography,
    CardMedia,
    FormHelperText,
} from '@mui/material';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SvgPegaseo from '../Svg/SvgPegaseo';
import FinalForm from '../FinalForm/FinalForm';
import FinalInput from '../FinalComponents/FinalInput';
import FinalCheckbox from '../FinalComponents/FinalCheckbox';
import FinalSubmitButton from '../FinalComponents/FinalSubmitButton';
import RouterLink from '../RouterLink/RouterLink';
import Copyright from '../Copyright/Copyright';
import backgroundVideo from '../../videos/sign_in_background.webm';
import cssStyles from './SignInStyles';

type FormType = {
    email: string,
    password: string,
    rememberMe: boolean,
};

const SignIn: React.FC = () => {
    // Use of hooks
    const [error, hasError] = React.useState<boolean>(false);

    // Use of redux
    const dispatch = useAppDispatch();

    // Use of react-cookie hooks
    const [/** cookie */, setCookie] = useCookies([cookieName]);

    // Function used to submit form
    const onSubmit = async (values: FormType) =>
        // Use form values to attempt sign in
        SecurityAPIs.signIn(values)
            // On successful call
            .then((data: any) => {
                if (null !== data) {
                    // Setup cookie or redux state
                    values.rememberMe ?
                        setCookie(cookieName, data, cookieOptions)
                        : dispatch(setInstanceState({ ...data, isLogged: true }));
                } else {
                    // Notify User that credentials are wrong
                    hasError(true);
                }
            });

    return (
        <>
            <Stack component='main' sx={cssStyles.formStructure}>
                <SvgPegaseo primary sx={{ width: 4/6 }} />
                <FinalForm<FormType>
                    initialValues={{ email: '', password: '', rememberMe: false }}
                    onSubmit={onSubmit}
                    width={3/5}
                    paddingTop={10}
                    paddingBottom={5}
                    rowGap={3}
                >
                    <FinalInput
                        fullWidth
                        name='email'
                        label='Adresse email'
                        wrapperSx={{ paddingBottom: 3 }}
                    />
                    <FinalInput
                        fullWidth
                        autoComplete='on'
                        type='password'
                        name='password'
                        label='Mot de passe'
                        wrapperSx={{ paddingBottom: 3 }}
                    />
                    <FinalCheckbox
                        name='rememberMe'
                        label='Se souvenir de moi'
                        wrapperSx={{ paddingBottom: 3 }}
                    />
                    {
                        error && (
                            <FormHelperText
                                error
                                sx={{ width: 1, textAlign: 'center', paddingTop: 0, paddingBottom: 3 }}
                            >
                                {'Identifiants invalides'}
                            </FormHelperText>
                        )
                    }
                    <FinalSubmitButton
                        fullWidth
                        enabledOnPristine
                        submitOnEnter
                        variant='contained'
                        endIcon={<MeetingRoomIcon />}
                        children='Se connecter'
                    />
                </FinalForm>
                <Stack direction='row' columnGap={4} paddingX={4}>
                    <RouterLink anchor='Mot de passe oublié ?' to='/forgot_password' variant='body2' />
                    <Typography variant='body2' align='right'>
                        {`Pas encore de compte ? `}
                        <RouterLink anchor='Inscrivez-vous' to='/sign_up' />
                    </Typography>
                </Stack>
                <Copyright paddingTop={10} />
            </Stack>
            <Box sx={cssStyles.videoFilter} />
            <CardMedia autoPlay loop muted component='video' src={backgroundVideo} sx={cssStyles.backgroundVideo} />
        </>
    );
};

export default SignIn;
